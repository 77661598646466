import Vue from "vue";
import vueSmoothScroll from "vue-smoothscroll";

Vue.use(vueSmoothScroll);

new Vue({
  el: "#app",
  data: {
    play_v: true,
    menu_enable: false,
  },
  watch: {
    play_v: function (val) {
      const v = document.getElementById("video");
      if (val) {
        v.play();
      } else {
        v.pause();
      }
    },
  },
  created() {},
  computed: {},
  methods: {
    clickSmoothScroll(target) {
      this.$SmoothScroll(document.querySelector("#" + target), 600, null, null, "y");
    },
    clickScrollTop() {
      this.$SmoothScroll(0, 600, null, null, "y");
    },
  },
});

$(function () {
  $(".inview").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).addClass("viewed");
    }
  });

  $(".fade-carousel").slick({
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    fade: true,
    arrows: false,
    dots: false,
  });

  $(".fade-mv").slick({
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1000,
    fade: true,
    arrows: false,
    dots: false,
  });

  $(window).scroll(function () {
    const is_top = $("#parallax").length > 0 ? true : false;

    if (!is_top) {
      return;
    }

    const scroll_top = $(window).scrollTop();
    const parallax_top = $("#parallax").offset().top;
    const parallax_h = $("#parallax").innerHeight();

    if (scroll_top + window.innerHeight - parallax_top > 0 && scroll_top - (parallax_h + parallax_top) < 0) {
      //console.log(scroll_top + window.innerHeight - parallax_top);
      //console.log(parallax_h);
      const param = ((scroll_top + window.innerHeight - parallax_top) / parallax_h) * 100;
      //console.log(((scroll_top + window.innerHeight - parallax_top) / parallax_h) * 100);
      $(".parallax").css({ "background-position-y": (-1 * param) / 2 + "px" });
    }
  });
});
